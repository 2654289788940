<template>
  <section class="case-creation__step case-creation__patient-information">
    <div class="case-creation__card">
      <div class="case-creation__heading edit-view">
        <div class="title-with-icon">
          <span class="icon">
            <img
              src="@/assets/images/icons/icon-patient-info.svg"
              alt="icon"
              class="img-fluid"
            />
          </span>
          <h3 class="title">Patient Information</h3>
        </div>
      </div>

      <div class="case-creation__body pb-0">
        <div class="vue-data">
          <el-row :gutter="20">
            <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
              <div class="input-value view-cases">
                <h4 class="name">First Name</h4>

                <p class="value">
                  {{ getPatientInfo ? getPatientInfo.first_name : "--" }}
                </p>
              </div>
            </el-col>
            <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
              <div class="input-value view-cases">
                <h4 class="name">Middle Name</h4>

                <p class="value">
                  {{ getPatientInfo ? getPatientInfo.middle_name : "--" }}
                </p>
              </div>
            </el-col>
            <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
              <div class="input-value view-cases">
                <h4 class="name">Last Name</h4>

                <p class="value">
                  {{ getPatientInfo ? getPatientInfo.last_name : "--" }}
                </p>
              </div>
            </el-col>
            <el-col :xl="3" :lg="3" :md="8" :sm="12" :xs="12">
              <div class="input-value view-cases">
                <h4 class="name">Gender</h4>

                <p class="value">
                  {{ getPatientInfo ? getPatientInfo.gender : "--" }}
                </p>
              </div>
            </el-col>
            <el-col :xl="3" :lg="3" :md="8" :sm="12" :xs="12">
              <div class="input-value view-cases">
                <h4 class="name">Date of Birth</h4>

                <p class="value">
                  {{
                    getPatientInfo
                      ? formatDate(getPatientInfo.date_of_birth)
                      : "--"
                  }}
                </p>
              </div>
            </el-col>

            <el-col :xl="3" :lg="3" :md="8" :sm="12" :xs="12">
              <div class="input-value view-cases">
                <h4 class="name">EMR</h4>

                <p class="value">
                  {{ getPatientInfo ? getPatientInfo.emr : "--" }}
                </p>
              </div>
            </el-col>
            <el-col :xl="3" :lg="3" :md="8" :sm="12" :xs="12">
              <div class="input-value view-cases">
                <h4 class="name">Mobile Number</h4>

                <p class="value">
                  {{ getPatientInfo ? getPatientInfo.home_phone : "--" }}
                </p>
              </div>
            </el-col>
            <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
              <div class="input-value view-cases">
                <h4 class="name">Phone Number</h4>

                <p class="value">
                  {{ getPatientInfo ? getPatientInfo.work_phone : "--" }}
                </p>
              </div>
            </el-col>
            <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
              <div class="input-value view-cases">
                <h4 class="name">Email</h4>

                <p class="value">
                  {{ getPatientInfo ? getPatientInfo.email : "--" }}
                </p>
              </div>
            </el-col>
            <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
              <div class="input-value view-cases">
                <h4 class="name">Address Line 1</h4>

                <p class="value">
                  {{ getPatientInfo ? getPatientInfo.address_line_1 : "--" }}
                </p>
              </div>
            </el-col>
            <el-col :xl="3" :lg="3" :md="8" :sm="12" :xs="12">
              <div class="input-value view-cases">
                <h4 class="name">Address Line 2</h4>

                <p class="value">
                  {{ getPatientInfo ? getPatientInfo.address_line_2 : "--" }}
                </p>
              </div>
            </el-col>
            <el-col :xl="3" :lg="3" :md="8" :sm="12" :xs="12">
              <div class="input-value view-cases">
                <h4 class="name">County</h4>

                <p class="value">
                  {{ getPatientInfo ? getPatientInfo.county : "--" }}
                </p>
              </div>
            </el-col>
            <el-col :xl="3" :lg="3" :md="8" :sm="12" :xs="12">
              <div class="input-value view-cases">
                <h4 class="name">Zip</h4>

                <p class="value">
                  {{ getPatientInfo ? getPatientInfo.zip : "--" }}
                </p>
              </div>
            </el-col>
            <el-col :xl="3" :lg="3" :md="8" :sm="12" :xs="12">
              <div class="input-value view-cases">
                <h4 class="name">State</h4>

                <p class="value">
                  {{ getPatientInfo ? getPatientInfo.state : "--" }}
                </p>
              </div>
            </el-col>

            <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
              <div class="input-value view-cases">
                <h4 class="name">City</h4>

                <p class="value">
                  {{ getPatientInfo ? getPatientInfo.city : "--" }}
                </p>
              </div>
            </el-col>
            <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
              <div class="input-value view-cases">
                <h4 class="name">Race</h4>

                <p class="value">
                  {{ getPatientInfo ? getPatientInfo.race : "--" }}
                </p>
              </div>
            </el-col>
            <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
              <div class="input-value view-cases">
                <h4 class="name">Ethnicity</h4>

                <p class="value">
                  {{ getPatientInfo ? getPatientInfo.ethnicity : "--" }}
                </p>
              </div>
            </el-col>
            <el-col
              :xl="3"
              :lg="4"
              :md="8"
              :sm="12"
              :xs="12"
              v-if="getPatientInfo && getPatientInfo.pregnant"
            >
              <div class="input-value view-cases">
                <h4 class="name">Pregnancy</h4>
                <p class="value">
                  {{ getPatientInformation.pregnant ? "Yes" : "No" }}
                </p>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters("clientPendingOrders", ["getPendingOrderDetails"]),
    getPatientInfo() {
      if (
        this.getPendingOrderDetails &&
        Object.keys(this.getPendingOrderDetails).length > 0 &&
        this.getPendingOrderDetails.patient_info
      ) {
        return this.getPendingOrderDetails.patient_info;
      }
      return null;
    },
  },
};
</script>
